<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbItems" />
    <hr>
    <c-flex
      mt="20px"
      justify-content="space-between"
    >
      <c-text
        flex="1"
        font-size="2xl"
      >
        Manajemen Glossary
      </c-text>
      <c-button
        v-if="isRoleSuperAdmin"
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        as="router-link"
        :to="{ name: 'superadmin.glossary-new' }"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Glossary
      </c-button>
    </c-flex>

    <c-box
      mt="30px"
      w="100%"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px #0000000D"
      rounded="lg"
      overflow="hidden"
    >
      <c-flex
        align-items="center"
        px="20px"
        h="75px"
      >
        <c-text flex="1">
          Daftar Glossary
        </c-text>
        <c-stack
          is-inline
          spacing="10px"
        >
          <c-menu :close-on-select="true">
            <c-menu-button
              border-radius="40px"
              background-color="superLightGray.900"
              pl="20px"
              pr="30px"
              py="10px"
              h="40px"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="240px"
            >
              <c-menu-option-group
                default-value="Semua"
                type="radio"
                @change="group = $event"
              >
                <c-menu-item-option
                  v-for="item in foodGroup"
                  :key="item.value"
                  :value="item.value"
                  border-bottom="1px solid #F2F2F2"
                  font-size="18px"
                  line-height="27px"
                  py="16px"
                  px="20px"
                >
                  {{ item.label }}
                </c-menu-item-option>
              </c-menu-option-group>
            </c-menu-list>
          </c-menu>
        </c-stack>

        <c-box
          border-left="1px solid #f2f2f2"
          h="70%"
          w="2px"
          mx="20px"
        />
        <c-input-group
          bg="#F2F2F2"
          rounded="md"
        >
          <c-input-left-addon
            bg="none"
            border="none"
          >
            <c-image :src="require('@/assets/ic-search.svg')" />
          </c-input-left-addon>
          <c-input
            v-model="search"
            bg="none"
            border="none"
            size="sm"
            px="0"
            placeholder="Pencarian"
            :_placeholder="{
              color: '#555',
              opacity: 1,
            }"
          />
        </c-input-group>
      </c-flex>

      <c-flex
        justify-content="flex-start"
        grid-gap="10px"
        overflow-x="auto"
        overflow-y="hidden"
        h="65px"
        pt="5px"
        px="15px"
        mt="20px"
      >
        <c-button
          v-for="item in chars"
          :key="item"
          type="button"
          ml="5px"
          h="32px"
          w="32px"
          min-w="32px"
          px="0"
          m="0"
          font-size="16px"
          line-height="24px"
          rounded="full"
          text-transform="uppercase"
          :color="char === item ? 'primary.400' : 'gray.900'"
          :bg="char === item ? '#008C8133' : 'white'"
          border-width="1px"
          border-style="solid"
          :border-color="char === item ? '#008C81' : 'gray.900'"
          @click.stop.prevent="char = item"
        >
          {{ item }}
        </c-button>
      </c-flex>

      <!-- Glossaries List -->
      <c-flex
        mt="20px"
        p="20px"
        grid-gap="20px"
        flex-wrap="wrap"
      >
        <template v-if="glossaries.length > 0">
          <c-button
            v-for="(item, idx) in glossaries"
            :key="idx"
            flex-dir="column"
            align-items="center"
            flex-shrink="0"
            as="router-link"
            :to="{
              name: 'superadmin.glossary-detail',
              params: { glossaryId: item.id },
            }"
            m="0"
            min-w="0"
            p="0"
            bg="none"
            h="fit-content"
            w="100px"
            :_hover="{
              bg: 'none',
            }"
          >
            <c-image
              h="72px"
              w="72px"
              rounded="md"
              object-fit="contain"
              :src="item.photoUrl"
              :alt="item.ingredientName"
            />
            <c-text
              v-chakra="{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }"
              font-size="12px"
              text-align="center"
              color="#333333"
              max-w="110px"
              overflow="hidden"
              font-weight="400"
              line-height="18px"
            >
              {{ item.ingredientName | trim }}
            </c-text>
            <c-text
              font-size="12px"
              text-align="center"
              color="#888888"
              font-weight="400"
              line-height="18px"
            >
              {{ item.dose }} {{ item.doseUnit }}
            </c-text>
          </c-button>
        </template>
        <EmptyCard
          v-else
          label="Glossary foto makan tidak tersedia"
        />
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import equal from 'fast-deep-equal'
import Breadcrumb from '@/components/breadcrumb.vue'
import EmptyCard from '@/views/profile/empty.vue'

export default {
  name: 'SUGlossaryList',
  components: {
    Breadcrumb,
    EmptyCard,
  },
  filters: {
    trim(value, length = 18) {
      if (value.length > length) {
        return `${value.substring(0, length)}...`
      }
      return value
    },
  },
  data() {
    return {
      glossaries: [],
      foodGroup: [],
      // ---
      chars: Array.from({ length: 122 - 97 + 1 }, (_, idx) => String.fromCharCode(97 + idx)),
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          label: 'Manajemen Glossary',
          href: null,
          isCurrent: true,
        },
      ]
    },
    search: {
      get() {
        return this.$route.query.q ?? ''
      },
      set(search) {
        if (search?.length <= 0) search = undefined
        this.$router.push({
          query: {
            ...this.$route.query,
            q: search,
          },
        })
      },
    },
    char: {
      get() {
        return this.$route.query.char ?? 'a'
      },
      set(char) {
        if (char === this.$route.query.char) return
        this.$router.push({ query: { ...this.$route.query, char } })
      },
    },
    group: {
      get() {
        return this.$route.query.group ?? ''
      },
      set(group) {
        this.$router.push({
          query: {
            ...this.$route.query,
            group,
          },
        })
      },
    },
    filters() {
      return {
        q: this.search,
        char: this.char,
        foodGroup: this.group,
      }
    },
    hasFilter() {
      return this.group != null && this.group !== ''
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler(filters, old) {
        if (equal(filters, old)) return

        let data = await this.$store.dispatch('suGlossary/list', filters)
        this.glossaries = data.result
        this.foodGroup = data.filter.map((it) => ({
          label: it,
          value: it,
        }))
      },
    },
  },
}
</script>
