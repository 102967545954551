var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr'), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "2xl"
    }
  }, [_vm._v(" Manajemen Glossary ")]), _vm.isRoleSuperAdmin ? _c('c-button', {
    attrs: {
      "h": "auto",
      "py": "12px",
      "px": "28px",
      "color": "#927102",
      "border-radius": "24px",
      "border-color": "transparent",
      "bg": "secondary.800",
      "variant-color": "inherit",
      "font-size": "16px",
      "line-height": "24px",
      "variant": "solid",
      "as": "router-link",
      "to": {
        name: 'superadmin.glossary-new'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-plus.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Tambah Glossary ")], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "w": "100%",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px #0000000D",
      "rounded": "lg",
      "overflow": "hidden"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "px": "20px",
      "h": "75px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1"
    }
  }, [_vm._v(" Daftar Glossary ")]), _c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "10px"
    }
  }, [_c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "40px",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "240px"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "Semua",
      "type": "radio"
    },
    on: {
      "change": function change($event) {
        _vm.group = $event;
      }
    }
  }, _vm._l(_vm.foodGroup, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "line-height": "27px",
        "py": "16px",
        "px": "20px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "border-left": "1px solid #f2f2f2",
      "h": "70%",
      "w": "2px",
      "mx": "20px"
    }
  }), _c('c-input-group', {
    attrs: {
      "bg": "#F2F2F2",
      "rounded": "md"
    }
  }, [_c('c-input-left-addon', {
    attrs: {
      "bg": "none",
      "border": "none"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg')
    }
  })], 1), _c('c-input', {
    attrs: {
      "bg": "none",
      "border": "none",
      "size": "sm",
      "px": "0",
      "placeholder": "Pencarian",
      "_placeholder": {
        color: '#555',
        opacity: 1
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-start",
      "grid-gap": "10px",
      "overflow-x": "auto",
      "overflow-y": "hidden",
      "h": "65px",
      "pt": "5px",
      "px": "15px",
      "mt": "20px"
    }
  }, _vm._l(_vm.chars, function (item) {
    return _c('c-button', {
      key: item,
      attrs: {
        "type": "button",
        "ml": "5px",
        "h": "32px",
        "w": "32px",
        "min-w": "32px",
        "px": "0",
        "m": "0",
        "font-size": "16px",
        "line-height": "24px",
        "rounded": "full",
        "text-transform": "uppercase",
        "color": _vm.char === item ? 'primary.400' : 'gray.900',
        "bg": _vm.char === item ? '#008C8133' : 'white',
        "border-width": "1px",
        "border-style": "solid",
        "border-color": _vm.char === item ? '#008C81' : 'gray.900'
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.char = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "p": "20px",
      "grid-gap": "20px",
      "flex-wrap": "wrap"
    }
  }, [_vm.glossaries.length > 0 ? _vm._l(_vm.glossaries, function (item, idx) {
    return _c('c-button', {
      key: idx,
      attrs: {
        "flex-dir": "column",
        "align-items": "center",
        "flex-shrink": "0",
        "as": "router-link",
        "to": {
          name: 'superadmin.glossary-detail',
          params: {
            glossaryId: item.id
          }
        },
        "m": "0",
        "min-w": "0",
        "p": "0",
        "bg": "none",
        "h": "fit-content",
        "w": "100px",
        "_hover": {
          bg: 'none'
        }
      }
    }, [_c('c-image', {
      attrs: {
        "h": "72px",
        "w": "72px",
        "rounded": "md",
        "object-fit": "contain",
        "src": item.photoUrl,
        "alt": item.ingredientName
      }
    }), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical'
        },
        expression: "{\n              display: '-webkit-box',\n              WebkitLineClamp: '2',\n              WebkitBoxOrient: 'vertical',\n            }"
      }],
      attrs: {
        "font-size": "12px",
        "text-align": "center",
        "color": "#333333",
        "max-w": "110px",
        "overflow": "hidden",
        "font-weight": "400",
        "line-height": "18px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("trim")(item.ingredientName)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "text-align": "center",
        "color": "#888888",
        "font-weight": "400",
        "line-height": "18px"
      }
    }, [_vm._v(" " + _vm._s(item.dose) + " " + _vm._s(item.doseUnit) + " ")])], 1);
  }) : _c('EmptyCard', {
    attrs: {
      "label": "Glossary foto makan tidak tersedia"
    }
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }